// title: Sitemap

import * as styles from './sitemap.module.scss'

import React from 'react'
import { Link, graphql, PageProps } from 'gatsby'

import { BaseLayout } from 'src/components/layout/Base'
import PageMetaData, { MetaData } from 'components/layout/PageMetaData'

interface Props extends PageProps<Queries.SitemapPageQuery> {}

const SitemapPage: React.FC<Props> = (props) => {
  const pages = props.data.allSitePage.pages.filter(
    (page) =>
      page.pageContext?.redirectTo == null &&
      page.pageContext?.noIndex != true &&
      !(page.pageContext?.url as string).match(/(404|dev-404-page)\/?$/),
  )

  return (
    <BaseLayout>
      <div className={styles.container}>
        <h1 className={styles.heading}>
          <Link to="/">Lucky Paper</Link> Site Map
        </h1>

        {pages.map((page, index) => (
          <a
            href={(page.pageContext as any).url}
            className={styles.link}
            key={index}
          >
            {((page.pageContext as any).title as string) ||
              ((page.pageContext as any).url as string)}
          </a>
        ))}
      </div>
    </BaseLayout>
  )
}

export const Head: React.FC<Props> = (props) => {
  const metaData: MetaData = {
    title: 'Sitemap - Lucky Paper',
    description: 'Sitemap for Lucky Paper',
  }

  return (
    <PageMetaData url={(props.pageContext as any).url} metaData={metaData} />
  )
}

export default SitemapPage

export const query = graphql`
  query SitemapPage {
    allSitePage {
      pages: nodes {
        pageContext
      }
    }
  }
`
